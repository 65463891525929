<template>
  <div>
    <div class="loading-wrap">
      <div class="inner-wrap" :class="{ isActive: loading }">
        <circle2></circle2>
      </div>
    </div>

    <!-- Pager Header -->
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row d-flex align-itmes-center">
              <div class="col-md-12">
                <h2>{{ this.$route.meta.title }}</h2>
                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <router-link to="/">الرئيسية</router-link>
                    </li>
                    <li>{{ this.$route.meta.title }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" alt="Parallax Image" />
      </parallax>
    </div>

    <b-container class="container">
      <div class="row" id="user-page-wrap">
        <!-- Widget -->
        <div class="col-md-5 col-lg-4 sidebar-wrapper">
          <div class="sidebar user-sidebar left">
            <div class="qrcode-wrap">
              <div v-if="owner === 0" class="d-flex align-items-center">
                <i class="ml-2 hi-qrcode2 font-18"></i>
                <h5>
                  سيظهر رمز الكيو ار(QR Code) الخاص بك عند اضافتك لأول عقار
                </h5>
              </div>
              <div
                v-else
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <div>
                  <img :src="imageQr" class="mb-2" alt="QR Scan Image" />
                </div>
                <qrcode-vue
                  :value="qrcode.value"
                  :size="qrcode.size"
                  level="H"
                ></qrcode-vue>
                <div class="qrcode-info text-center">
                  <h3 class="mb-1">كود QR واحد لجميع عقاراتك</h3>
                  <p>يمكنك قراءة الـ QR كود وطباعته لسهولة الوصول لعقاراتك</p>
                </div>
              </div>
            </div>

            <div class="my-account-nav-container">
              <b-nav vertical class="my-account-nav">
                <b-nav-text class="sub-nav-title">ادارة الحساب</b-nav-text>
                <b-nav-item to="/profile"
                  ><i class="hi-user"></i> الملف الشخصي</b-nav-item
                >
                <!-- <b-nav-item><i class="hi-alarm"></i>التنبيهات</b-nav-item> -->
                <b-nav-item to="/change-password"
                  ><i class="hi-lock"></i> تغيير كلمة المرور</b-nav-item
                >
              </b-nav>

              <b-nav vertical class="my-account-nav">
                <b-nav-text class="sub-nav-title">ادارة العقارات</b-nav-text>
                <b-nav-item
                  :to="{ name: 'properties', params: { id: user._id } }"
                  ><i class="hi-apartment"></i> عقاراتي
                </b-nav-item>
                <b-nav-item
                  :to="{ name: 'properties', params: { id: 'my-favorites' } }"
                  ><i class="hi-bookmark"></i> مفضلتي
                </b-nav-item>
                <!-- <b-nav-item :to="{name: 'my-requests'}"><i class="hi-paper-plane"></i> طلباتي</b-nav-item> -->
                <b-nav-item to="/add-property/new"
                  ><i class="hi-plus"></i> اضافة عقار جديد</b-nav-item
                >
              </b-nav>

              <b-nav vertical class="my-account-nav">
                <b-nav-item @click="loggedOut"
                  ><i class="hi-power-switch"></i> تسجيل الخروج</b-nav-item
                >
              </b-nav>
            </div>
          </div>
        </div>

        <div class="col-md-7 col-lg-8">
          <!-- <div class="row"> -->
          <div class="col-md-12 my-profile my-account mb-5">
            <h4 class="margin-top-0">ملفي الشخصي</h4>
            <div>
              <label>الاسم</label>
              <b-form-input
                v-model="name"
                type="text"
                :class="{ 'has-error': $v.name.$error }"
              ></b-form-input>
              <span
                v-if="submitted && !$v.name.required"
                class="invalid-feedback d-block"
                >من فضلك أدخل الإسم</span
              >
            </div>
            <div>
              <label>نوع الحساب</label>
              <multiselect
                v-model="userType"
                :show-labels="false"
                :class="{ 'has-error': $v.userType.$error }"
                placeholder="اختر نوع الحساب"
                :options="filteredUserTypes"
                track-by="code"
                label="nameAr"
                :allow-empty="false"
              >
                <template slot="noOptions">لا توجد عناصر</template>
                <template slot="noResult">لا يوجد نوع حساب بهذا الاسم</template>
              </multiselect>
            </div>

            <div v-if="!propertySeeker">
              <div ref="clientType" class="mb-4 mx-auto">
                <label>نوع العميل</label>
                <multiselect
                  id="account-type"
                  v-model="clientType"
                  :show-labels="false"
                  :class="{ 'has-error': $v.clientType.$error }"
                  placeholder="اختر نوع العميل"
                  :options="clientTypes"
                  track-by="_id"
                  value-field="code"
                  text-field="nameAr"
                  label="name"
                >
                  <template slot="noOptions">لا توجد عناصر</template>
                </multiselect>
                <span
                  v-if="submitted && !$v.clientType.required"
                  class="invalid-feedback d-block"
                  >من فضلك اختر نوع الحساب</span
                >
              </div>

              <div v-if="isIndividual">
                <div ref="idType" class="mb-4 mx-auto">
                  <label>الصفة</label>
                  <multiselect
                    v-model="idType"
                    :show-labels="false"
                    :class="{ 'has-error': $v.idType.$error }"
                    placeholder="اختر الصفة"
                    :options="idTypes"
                    track-by="code"
                    value-field="code"
                    text-field="name"
                    label="name"
                  ></multiselect>
                </div>

                <div ref="idNumber">
                  <p>
                    <label for="idNumber">
                      رقم الهوية
                      <i class="hi-user"></i>
                      <b-form-input
                        v-model="idNumber"
                        :class="{ 'has-error': $v.idNumber.$error }"
                        placeholder="ادخل رقم الهوية"
                        :customValidate="/^[0-9]*$/"
                      />
                    </label>
                    <span
                      v-if="submitted && !$v.idNumber.required"
                      class="invalid-feedback d-block"
                      >من فضلك أدخل رقم الهوية</span
                    >
                    <span
                      v-else-if="submitted && !$v.idNumber.numeric"
                      class="invalid-feedback d-block"
                      >رقم الهوية يجب أن يحتوي على أرقام فقط</span
                    >
                  </p>
                </div>
              </div>

              <div v-else-if="clientType && !isIndividual" ref="licenseNumber">
                <p>
                  <label for="licenseNumber">
                    السجل التجاري
                    <i class="hi-user"></i>
                    <b-form-input
                      v-model="licenseNumber"
                      :class="{ 'has-error': $v.licenseNumber.$error }"
                      placeholder="ادخل السجل التجاري"
                      :customValidate="/^[0-9]*$/"
                    />
                  </label>
                  <span
                    v-if="submitted && !$v.licenseNumber.required"
                    class="invalid-feedback d-block"
                    >من فضلك أدخل السجل التجاري</span
                  >
                  <span
                    v-else-if="submitted && !$v.licenseNumber.numeric"
                    class="invalid-feedback d-block"
                  >
                    السجل التجاري يجب ان يحتوي على أرقام فقط
                  </span>
                </p>
              </div>

              <div ref="registrationNumber">
                <p>
                  <label for="registrationNumber">
                    رقم الترخيص (رقم المعلن)
                    <i class="hi-user"></i>
                    <b-form-input
                      v-model="registrationNumber"
                      :class="{ 'has-error': $v.registrationNumber.$error }"
                      placeholder="ادخل رقم الترخيص (رقم المعلن)"
                      :customValidate="/^[0-9]*$/"
                    />
                  </label>
                  <span
                    v-if="submitted && !$v.registrationNumber.required"
                    class="invalid-feedback d-block"
                    >من فضلك أدخل رقم الترخيص (رقم المعلن)</span
                  >
                  <span
                    v-else-if="submitted && !$v.registrationNumber.numeric"
                    class="invalid-feedback d-block"
                    >رقم الترخيص (رقم المعلن) يجب أن يحتوي على أرقام فقط</span
                  >
                </p>
              </div>
            </div>

            <div v-if="showLocation">
              <label for="username">
                اسم المكتب او الشركه
                <i class="hi-user"></i>
                <b-form-input
                  v-model="company"
                  :class="{ 'has-error': $v.company.$error }"
                  placeholder="ادخل المكتب او الشركه هنا"
                />
              </label>
              <span
                v-if="submitted && !$v.company.required"
                class="invalid-feedback d-block"
                >من فضلك أدخل الإسم بشكل صحيح</span
              >
            </div>

            <label>رقم الهاتف</label>
            <b-form-input
              v-model="phoneNumber"
              type="text"
              :readonly="true"
            ></b-form-input>

            <label>البريد الالكتروني</label>

            <b-form-input
              v-model="email"
              type="text"
              :class="{ 'has-error': $v.email.$error }"
            ></b-form-input>
            <span
              v-if="submitted && email && !$v.email.email"
              class="invalid-feedback d-block"
              >من فضلك أدخل بريدك الكتروني صحيح</span
            >

            <span
              v-if="submitted && !$v.userType.required"
              class="invalid-feedback d-block"
              >من فضلك اختر نوع الحساب</span
            >

            <div v-if="showLocation" class="my-4">
              <GoogleMapsSearch
                :profile="profile"
                :class="{ 'has-error': location === '' }"
                @on-validate="setCurrentPlace"
              />
              <p v-if="location === ''" class="invalid-feedback d-block mt-5">
                من فضلك حدد موقع المكتب او الشركه العقاريه
              </p>
              <p v-else class="d-block mt-5">
                لتغير الموقع الخاص بشركتك او مكتبك العقاري يجد سحب ال pin علي
                الخريطه للمواقع الجديد
              </p>
            </div>

            <b-button
              variant="primary"
              type="submit"
              @click.prevent="onSubmitForm"
              :disabled="spinnerLoading"
              class="button mt-5 mb-2"
            >
              <span>حفظ التغيرات</span>
              <b-spinner v-show="spinnerLoading" small class="mr-2"></b-spinner>
            </b-button>
          </div>
        </div>
      </div>
    </b-container>

    <div class="margin-top-55"></div>

    <!-- Footer -->
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {
  email,
  required,
  minLength,
  requiredIf,
  numeric
} from 'vuelidate/lib/validators'
import { Circle2 } from 'vue-loading-spinner'
import Parallax from 'vue-parallaxy'
import ResizeMixin from '@/mixins/ResizeHandler'
import GoogleMapsSearch from '@/components/map/GoogleMapsSearch.vue'
import '@/styles/user/profile.scss'
import { realEstateService } from '../../services'

export default {
  name: 'Profile',
  layout: 'user',
  components: {
    Multiselect,
    Circle2,
    Parallax,
    GoogleMapsSearch,
    QrcodeVue
  },
  mixins: [ResizeMixin],
  data() {
    return {
      loading: false,
      titleBg: require('@/assets/images/home-parallax-2.jpg'),
      stikyKit: {
        options: {
          parent: '.sidebar-wrapper',
          offset_top: 118
        }
      },
      name: '',
      company: null,
      phoneNumber: null,
      email: null,
      userType: null,
      userTypeValue: null,
      submitted: false,
      phoneData: {},
      address: '',
      changedValues: {},
      location: {},
      owner: 0,
      licenseNumber: '',
      profile: null,
      clientType: null,
      idType: null,
      clientTypes: [
        {
          _id: 1,
          name: 'فرد',
          code: 'individual'
        },
        {
          _id: 2,
          name: 'شركه',
          code: 'company'
        }
      ],
      idTypes: [
        { name: 'مواطن', code: 'citizen' },
        { name: 'مقيم', code: 'resident' }
      ],
      registrationNumber: null,
      idNumber: null
    }
  },
  mounted() {
    this.mapData()
    this.setSpinnerLoading(false)
  },

  created() {
    const query = `account=${this.user?._id}&pageSize=1&pageNumber=1`
    realEstateService.ownerRealEstates(query).then((results) => {
      this.owner = results.data.count || 0
    })
  },
  computed: {
    ...mapState('accountState', ['configs', 'user']),
    ...mapState('userState', ['spinnerLoading']),
    errorMsg() {
      if (this.name.length < 3) return 'لا يمكن ان يكون الاسم اقل من 3 أحرف'
      else if (this.showLocation && (!this.company || this.company.length < 3))
        return 'لا يمكن ان يكون اسم الشركه اقل من 3 أحرف'
      else if (
        !this.propertySeeker &&
        (!this.registrationNumber || this.registrationNumber.length < 6)
      )
        return 'لا يمكن ان يكون رقم الترخيص (رقم المعلن) اقل من 6 أرقام'
      return 'من فضلك تأكد من جميع الحقول'
    },
    userTypes() {
      return this.configs.userTypes.map((item) => ({
        nameAr: item.nameAr,
        nameEn: item.nameEn,
        code: item.code,
        id: item._id
      }))
    },
    key() {
      return this.$route.fullPath
    },
    showFooter() {
      return this.$route.meta.showFooter
    },
    imageQr() {
      if (this.user.imageQr) {
        return this.user.imageQr
      }
      return false
    },
    showLocation() {
      return (
        this.userType?.code === 'office' || this.userType?.code === 'company'
      )
    },
    qrcode() {
      return {
        value: `${window.location.origin}/properties/${this.user._id}`,
        size: 100
      }
    },
    propertySeeker() {
      return this.userType?.code === 'seeker'
    },
    isIndividual() {
      return this.clientType?.code === 'individual'
    },
    filteredUserTypes() {
      return this.user?.type === 'seeker'
        ? this.userTypes
        : this.userTypes.filter((type) => type.code !== 'seeker')
    }
  },
  watch: {
    user: function (val) {
      this.mapData(val)
    },
    userType(val) {
      if (val?.code === 'seeker') {
        this.clientType = null
        this.idType = null
      }
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    email: {
      email
    },
    company: {
      minLength: minLength(3),
      required: requiredIf(function () {
        return this.showLocation
      })
    },
    location: {
      required: requiredIf(function () {
        return this.showLocation
      })
    },
    userType: {
      required
    },
    clientType: {
      required: requiredIf(function () {
        return !this.propertySeeker
      })
    },
    idType: {
      required: requiredIf(function () {
        return this.isIndividual
      })
    },
    registrationNumber: {
      numeric: numeric,
      minLength: minLength(6),
      required: requiredIf(function () {
        return !this.propertySeeker
      })
    },
    licenseNumber: {
      numeric: numeric,
      required: requiredIf(function () {
        return this.clientType && !this.isIndividual
      })
    },
    idNumber: {
      numeric: numeric,
      required: requiredIf(function () {
        return this.isIndividual
      })
    },
    form: [
      'name',
      'email',
      'company',
      'userType',
      'location',
      'licenseNumber',
      'registrationNumber',
      'clientType',
      'idType',
      'idNumber'
    ]
  },
  methods: {
    ...mapActions('accountState', ['updateProfileData', 'logout']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    navbarClick(route) {
      this.$router.push(route)
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.$store.dispatch('authState/logout')
        this.$router.push({ name: 'login' })
      }
    },
    beforeLeave() {
      this.loading = true
    },
    afterEnter() {
      this.loading = false
    },
    loggedOut() {
      this.logout()
    },
    mapData() {
      if (this.user) {
        this.name = this.user.name
        this.address = this.user.address
        this.email = this.user.email
        this.licenseNumber = this.user.licenseNumber

        if (this.user.company) {
          this.company = this.user.company
        }
        if (this.user.registrationNumber)
          this.registrationNumber = this.user.registrationNumber
        this.phoneNumber = this.user.phoneNumber
        this.userType = this.userTypes.find(
          (item) => item.code === this.user.type
        )
        this.clientType = this.clientTypes.find(
          (type) => this.user.clientType === type.code
        )
        this.idType = this.idTypes.find(
          (type) => this.user.idType === type.code
        )
        this.location = this.user.location
        if (this.location) {
          this.profile = {
            coordinates: {
              lat: this.user.location.coordinates[1],
              lng: this.user.location.coordinates[0]
            },
            address: this.address
          }
        }

        this.idNumber = this.user.idNumber
      }
    },
    getChangedValues() {
      this.changedValues = {}
      // if(this.phoneNumber !== `'${this.user.phoneNumber}'`){
      if (this.phoneNumber !== this.user.phoneNumber) {
        let phone = this.phoneNumber.replace(/ /g, '').substr(1)
        this.changedValues.phoneNumber = parseInt(phone)
      }
      if (this.name !== this.user.name) this.changedValues.name = this.name
      if (this.company !== this.user.company)
        this.changedValues.company = this.company
      if (this.email !== this.user.email) this.changedValues.email = this.email
      if (this.licenseNumber !== this.user.licenseNumber)
        this.changedValues.licenseNumber = this.licenseNumber
      if (this.userType.code !== this.user.type)
        this.changedValues.type = this.userType.code
      if (this.address !== this.user?.address) {
        this.changedValues.location = this.location
        this.changedValues.address = this.address
      }
      if (this.clientType?.code !== this.user.clientType)
        this.changedValues.clientType = this.clientType?.code || null
      if (this.idType?.code !== this.user.idType)
        this.changedValues.idType = this.idType?.code || null
      if (this.registrationNumber !== this.user.registrationNumber)
        this.changedValues.registrationNumber = this.registrationNumber
      if (this.idNumber !== this.user.idNumber)
        this.changedValues.idNumber = this.idNumber
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    setCurrentPlace({ place }) {
      this.location = {
        type: 'Point',
        coordinates: [place.lng, place.lat]
      }
      this.address = place.address
    },
    onSubmitForm() {
      this.submitted = true

      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.setSpinnerLoading(true)

      if (!isValid) {
        if (this.showLocation && !this.location) {
          this.errNotifaction('من فضلك حدد موقع المكتب او  الشركه  العقاريه')
        } else {
          this.errNotifaction(this.errorMsg)
        }
        setTimeout(() => {
          this.setSpinnerLoading(false)
        }, 1000)
      } else {
        this.getChangedValues()
        this.updateProfileInfo()
      }
    },
    updateProfileInfo() {
      if (
        this.changedValues &&
        Object.keys(this.changedValues).length === 0 &&
        this.changedValues.constructor === Object
      ) {
        this.setSpinnerLoading(false)
        return this.$toasted.show('لم يتم تغيير البيانات', {
          duration: 2000,
          type: 'error',
          icon: 'fa-times'
        })
      } else {
        this.updateProfileData({
          ...this.changedValues,
          _id: this.user._id
        })
          .then(() => {
            this.$toasted.show('تم تحديث الملف الشخصي بنجاح!', {
              duration: 2000,
              type: 'success',
              icon: 'fa-check'
            })
          })
          .catch(() => {
            this.$toasted.show('خطأ في تعديل الملف الشخصي', {
              duration: 2000,
              type: 'error',
              icon: 'fa-times'
            })
          })
          .finally(() => {
            this.setSpinnerLoading(false)
          })
      }
    },
    errNotifaction(val) {
      this.$toasted.show(val, {
        duration: 2000,
        type: 'error',
        icon: 'fa-times'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}
</style>
